import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f66111a0 = () => interopDefault(import('../pages/assets/index.vue' /* webpackChunkName: "pages/assets/index" */))
const _7bcc8b08 = () => interopDefault(import('../pages/buildings/index.vue' /* webpackChunkName: "pages/buildings/index" */))
const _f496adde = () => interopDefault(import('../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _6b5d993a = () => interopDefault(import('../pages/cognito/index.vue' /* webpackChunkName: "pages/cognito/index" */))
const _d9025900 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _750963bc = () => interopDefault(import('../pages/decarb/index.vue' /* webpackChunkName: "pages/decarb/index" */))
const _1236dd57 = () => interopDefault(import('../pages/funds/index.vue' /* webpackChunkName: "pages/funds/index" */))
const _de783be8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _766bf9eb = () => interopDefault(import('../pages/ordinances/index.vue' /* webpackChunkName: "pages/ordinances/index" */))
const _675bc6e7 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _1ea48840 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _c91b56de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _a51b033a = () => interopDefault(import('../pages/assets/detail.vue' /* webpackChunkName: "pages/assets/detail" */))
const _7e09061c = () => interopDefault(import('../pages/assets/edit.vue' /* webpackChunkName: "pages/assets/edit" */))
const _3732d4cb = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _830f1f20 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _f866c5ac = () => interopDefault(import('../pages/auth/hjlogin.vue' /* webpackChunkName: "pages/auth/hjlogin" */))
const _65e9885c = () => interopDefault(import('../pages/auth/loggingout.vue' /* webpackChunkName: "pages/auth/loggingout" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _62174bbb = () => interopDefault(import('../pages/auth/loginnotfound.vue' /* webpackChunkName: "pages/auth/loginnotfound" */))
const _cfdcdcdc = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _7a8da4d0 = () => interopDefault(import('../pages/buildings/contacts/index.vue' /* webpackChunkName: "pages/buildings/contacts/index" */))
const _28a6d7ce = () => interopDefault(import('../pages/buildings/detail/index.vue' /* webpackChunkName: "pages/buildings/detail/index" */))
const _4e3ecf05 = () => interopDefault(import('../pages/buildings/emissions/index.vue' /* webpackChunkName: "pages/buildings/emissions/index" */))
const _4dddd945 = () => interopDefault(import('../pages/buildings/energy/index.vue' /* webpackChunkName: "pages/buildings/energy/index" */))
const _4e6f64d8 = () => interopDefault(import('../pages/buildings/gavs/index.vue' /* webpackChunkName: "pages/buildings/gavs/index" */))
const _351c0e08 = () => interopDefault(import('../pages/buildings/gbcs/index.vue' /* webpackChunkName: "pages/buildings/gbcs/index" */))
const _28537247 = () => interopDefault(import('../pages/buildings/meters/index.vue' /* webpackChunkName: "pages/buildings/meters/index" */))
const _5a531a8c = () => interopDefault(import('../pages/buildings/notes/index.vue' /* webpackChunkName: "pages/buildings/notes/index" */))
const _55e8918a = () => interopDefault(import('../pages/buildings/ordinances/index.vue' /* webpackChunkName: "pages/buildings/ordinances/index" */))
const _4e6a9837 = () => interopDefault(import('../pages/buildings/projects/index.vue' /* webpackChunkName: "pages/buildings/projects/index" */))
const _6c6d38af = () => interopDefault(import('../pages/buildings/ratings/index.vue' /* webpackChunkName: "pages/buildings/ratings/index" */))
const _01f6dfa8 = () => interopDefault(import('../pages/buildings/renewableenergy/index.vue' /* webpackChunkName: "pages/buildings/renewableenergy/index" */))
const _4b9b9bd8 = () => interopDefault(import('../pages/buildings/scorecard.vue' /* webpackChunkName: "pages/buildings/scorecard" */))
const _7f6b5934 = () => interopDefault(import('../pages/buildings/tenantengagement/index.vue' /* webpackChunkName: "pages/buildings/tenantengagement/index" */))
const _01d00bd3 = () => interopDefault(import('../pages/buildings/waste/index.vue' /* webpackChunkName: "pages/buildings/waste/index" */))
const _0c877590 = () => interopDefault(import('../pages/buildings/water/index.vue' /* webpackChunkName: "pages/buildings/water/index" */))
const _4ec15a3c = () => interopDefault(import('../pages/clients/contact/index.vue' /* webpackChunkName: "pages/clients/contact/index" */))
const _46446e6e = () => interopDefault(import('../pages/clients/energystar/index.vue' /* webpackChunkName: "pages/clients/energystar/index" */))
const _0b4e61ac = () => interopDefault(import('../pages/clients/gbc/index.vue' /* webpackChunkName: "pages/clients/gbc/index" */))
const _9a2b6f7c = () => interopDefault(import('../pages/clients/goals/index.vue' /* webpackChunkName: "pages/clients/goals/index" */))
const _b8d341cc = () => interopDefault(import('../pages/dashboard/admin.vue' /* webpackChunkName: "pages/dashboard/admin" */))
const _316c459d = () => interopDefault(import('../pages/funds/datacoverage.vue' /* webpackChunkName: "pages/funds/datacoverage" */))
const _661045c8 = () => interopDefault(import('../pages/funds/detail.vue' /* webpackChunkName: "pages/funds/detail" */))
const _face81d6 = () => interopDefault(import('../pages/funds/edit.vue' /* webpackChunkName: "pages/funds/edit" */))
const _4735e4d1 = () => interopDefault(import('../pages/funds/emissions.vue' /* webpackChunkName: "pages/funds/emissions" */))
const _620f03d3 = () => interopDefault(import('../pages/funds/energy.vue' /* webpackChunkName: "pages/funds/energy" */))
const _4044f073 = () => interopDefault(import('../pages/funds/energyratings.vue' /* webpackChunkName: "pages/funds/energyratings" */))
const _f521b404 = () => interopDefault(import('../pages/funds/gbcrollups.vue' /* webpackChunkName: "pages/funds/gbcrollups" */))
const _1990139f = () => interopDefault(import('../pages/funds/waste.vue' /* webpackChunkName: "pages/funds/waste" */))
const _3594cf1c = () => interopDefault(import('../pages/funds/water.vue' /* webpackChunkName: "pages/funds/water" */))
const _213359f0 = () => interopDefault(import('../pages/ordinances/detail.vue' /* webpackChunkName: "pages/ordinances/detail" */))
const _43c3c001 = () => interopDefault(import('../pages/ordinances/edit.vue' /* webpackChunkName: "pages/ordinances/edit" */))
const _540c3210 = () => interopDefault(import('../pages/properties/contacts/index.vue' /* webpackChunkName: "pages/properties/contacts/index" */))
const _1c2e7060 = () => interopDefault(import('../pages/properties/details.vue' /* webpackChunkName: "pages/properties/details" */))
const _4ff76b6c = () => interopDefault(import('../pages/properties/edit.vue' /* webpackChunkName: "pages/properties/edit" */))
const _2855a192 = () => interopDefault(import('../pages/properties/notes/index.vue' /* webpackChunkName: "pages/properties/notes/index" */))
const _f9685ab6 = () => interopDefault(import('../pages/buildings/components/EsIdModal.vue' /* webpackChunkName: "pages/buildings/components/EsIdModal" */))
const _637ce199 = () => interopDefault(import('../pages/buildings/components/Walkscores.vue' /* webpackChunkName: "pages/buildings/components/Walkscores" */))
const _bfcd76f0 = () => interopDefault(import('../pages/buildings/detail/detailConfig.js' /* webpackChunkName: "pages/buildings/detail/detailConfig" */))
const _e8d7cc84 = () => interopDefault(import('../pages/buildings/detail/edit.vue' /* webpackChunkName: "pages/buildings/detail/edit" */))
const _bfa5586e = () => interopDefault(import('../pages/buildings/detail/editname.vue' /* webpackChunkName: "pages/buildings/detail/editname" */))
const _85c467b6 = () => interopDefault(import('../pages/buildings/meters/edit.vue' /* webpackChunkName: "pages/buildings/meters/edit" */))
const _39826fa2 = () => interopDefault(import('../pages/buildings/meters/meterConfig.js' /* webpackChunkName: "pages/buildings/meters/meterConfig" */))
const _3e8f18b1 = () => interopDefault(import('../pages/buildings/ordinances/edit.vue' /* webpackChunkName: "pages/buildings/ordinances/edit" */))
const _b4dbaf96 = () => interopDefault(import('../pages/buildings/projects/edit.vue' /* webpackChunkName: "pages/buildings/projects/edit" */))
const _61d9368e = () => interopDefault(import('../pages/clients/detail/detail.vue' /* webpackChunkName: "pages/clients/detail/detail" */))
const _5e85cd07 = () => interopDefault(import('../pages/clients/detail/edit.vue' /* webpackChunkName: "pages/clients/detail/edit" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _136addc4 = () => interopDefault(import('../pages/contacts/_contactId.vue' /* webpackChunkName: "pages/contacts/_contactId" */))
const _1424ee50 = () => interopDefault(import('../pages/projects/_projectId.vue' /* webpackChunkName: "pages/projects/_projectId" */))
const _04d4e7e4 = () => interopDefault(import('../pages/users/_userId.vue' /* webpackChunkName: "pages/users/_userId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/assets",
    component: _f66111a0,
    name: "assets"
  }, {
    path: "/buildings",
    component: _7bcc8b08,
    name: "buildings"
  }, {
    path: "/clients",
    component: _f496adde,
    name: "clients"
  }, {
    path: "/cognito/:buildingId/form",
    component: _6b5d993a,
    name: "cognito"
  }, {
    path: "/contacts",
    component: _d9025900,
    name: "contacts"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    alias: ["/dashboard/data","/dashboard/gbcsdetail"],
    name: "dashboard"
  }, {
    path: "/decarb",
    component: _750963bc,
    alias: ["/decarb/fund","/decarb/stranding","/decarb/buildingdatapoints","/decarb/washingtondcbeps"],
    name: "decarb"
  }, {
    path: "/funds",
    component: _1236dd57,
    name: "funds"
  }, {
    path: "/home",
    component: _de783be8,
    name: "home"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/ordinances",
    component: _766bf9eb,
    name: "ordinances"
  }, {
    path: "/projects",
    component: _675bc6e7,
    name: "projects"
  }, {
    path: "/properties",
    component: _1ea48840,
    name: "properties"
  }, {
    path: "/users",
    component: _c91b56de,
    name: "users"
  }, {
    path: "/assets/:assetId/detail",
    component: _a51b033a,
    name: "assets-detail"
  }, {
    path: "/assets/:assetId/edit",
    component: _7e09061c,
    name: "assets-edit"
  }, {
    path: "/auth/check",
    component: _3732d4cb,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _830f1f20,
    name: "auth-forgot"
  }, {
    path: "/auth/hjlogin",
    component: _f866c5ac,
    name: "auth-hjlogin"
  }, {
    path: "/auth/loggingout",
    component: _65e9885c,
    name: "auth-loggingout"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/loginnotfound",
    component: _62174bbb,
    name: "auth-loginnotfound"
  }, {
    path: "/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset"
  }, {
    path: "/buildings/:buildingId/contacts",
    component: _7a8da4d0,
    name: "buildings-contacts"
  }, {
    path: "/buildings/:buildingId/detail",
    component: _28a6d7ce,
    name: "buildings-detail"
  }, {
    path: "/buildings/:buildingId/emissions",
    component: _4e3ecf05,
    name: "buildings-emissions"
  }, {
    path: "/buildings/:buildingId/energy",
    component: _4dddd945,
    name: "buildings-energy"
  }, {
    path: "/buildings/:buildingId/gavs",
    component: _4e6f64d8,
    name: "buildings-gavs"
  }, {
    path: "/buildings/:buildingId/gbcs",
    component: _351c0e08,
    name: "buildings-gbcs"
  }, {
    path: "/buildings/:buildingId/meters",
    component: _28537247,
    name: "buildings-meters"
  }, {
    path: "/buildings/:buildingId/notes",
    component: _5a531a8c,
    name: "buildings-notes"
  }, {
    path: "/buildings/:buildingId/ordinances",
    component: _55e8918a,
    name: "buildings-ordinances"
  }, {
    path: "/buildings/:buildingId/projects",
    component: _4e6a9837,
    name: "buildings-projects"
  }, {
    path: "/buildings/:buildingId/ratings",
    component: _6c6d38af,
    name: "buildings-ratings"
  }, {
    path: "/buildings/:buildingId/renewableenergy",
    component: _01f6dfa8,
    name: "buildings-renewableenergy"
  }, {
    path: "/buildings/:buildingId/scorecard",
    component: _4b9b9bd8,
    name: "buildings-scorecard"
  }, {
    path: "/buildings/:buildingId/tenantengagement",
    component: _7f6b5934,
    name: "buildings-tenantengagement"
  }, {
    path: "/buildings/:buildingId/waste",
    component: _01d00bd3,
    name: "buildings-waste"
  }, {
    path: "/buildings/:buildingId/water",
    component: _0c877590,
    name: "buildings-water"
  }, {
    path: "/clients/:clientId/contacts",
    component: _4ec15a3c,
    name: "clients-contact"
  }, {
    path: "/clients/:clientId/energystar",
    component: _46446e6e,
    name: "clients-energystar"
  }, {
    path: "/clients/:clientId/gbc",
    component: _0b4e61ac,
    name: "clients-gbc"
  }, {
    path: "/clients/:clientId/goals",
    component: _9a2b6f7c,
    name: "clients-goals"
  }, {
    path: "/dashboard/admin",
    component: _b8d341cc,
    name: "dashboard-admin"
  }, {
    path: "/funds/datacoverage",
    component: _316c459d,
    name: "funds-datacoverage"
  }, {
    path: "/funds/:fundId/detail",
    component: _661045c8,
    name: "funds-detail"
  }, {
    path: "/funds/:fundId/edit",
    component: _face81d6,
    name: "funds-edit"
  }, {
    path: "/funds/emissions",
    component: _4735e4d1,
    name: "funds-emissions"
  }, {
    path: "/funds/energy",
    component: _620f03d3,
    name: "funds-energy"
  }, {
    path: "/funds/energyratings",
    component: _4044f073,
    name: "funds-energyratings"
  }, {
    path: "/funds/gbcrollups",
    component: _f521b404,
    name: "funds-gbcrollups"
  }, {
    path: "/funds/waste",
    component: _1990139f,
    name: "funds-waste"
  }, {
    path: "/funds/water",
    component: _3594cf1c,
    name: "funds-water"
  }, {
    path: "/ordinances/:ordinanceId/detail",
    component: _213359f0,
    name: "ordinances-detail"
  }, {
    path: "/ordinances/:ordinanceId/edit",
    component: _43c3c001,
    name: "ordinances-edit"
  }, {
    path: "/properties/:propertyId/contacts",
    component: _540c3210,
    name: "properties-contacts"
  }, {
    path: "/properties/:propertyId/detail",
    component: _1c2e7060,
    name: "properties-details"
  }, {
    path: "/properties/:propertyId/edit",
    component: _4ff76b6c,
    name: "properties-edit"
  }, {
    path: "/properties/:propertyId/notes",
    component: _2855a192,
    name: "properties-notes"
  }, {
    path: "/buildings/components/EsIdModal",
    component: _f9685ab6,
    name: "buildings-components-EsIdModal"
  }, {
    path: "/buildings/components/Walkscores",
    component: _637ce199,
    name: "buildings-components-Walkscores"
  }, {
    path: "/buildings/detail/detailConfig",
    component: _bfcd76f0,
    name: "buildings-detail-detailConfig"
  }, {
    path: "/buildings/:buildingId/detail/edit",
    component: _e8d7cc84,
    name: "buildings-detail-edit"
  }, {
    path: "/buildings/:buildingId/detail/editname",
    component: _bfa5586e,
    name: "buildings-detail-editname"
  }, {
    path: "/buildings/:buildingId/meters/edit/:currId/:type",
    component: _85c467b6,
    name: "buildings-meters-edit"
  }, {
    path: "/buildings/meters/meterConfig",
    component: _39826fa2,
    name: "buildings-meters-meterConfig"
  }, {
    path: "/buildings/:buildingId/ordinances/:resourceId/edit",
    component: _3e8f18b1,
    name: "buildings-ordinances-edit"
  }, {
    path: "/buildings/:buildingId/projects/:projectId",
    component: _b4dbaf96,
    name: "buildings-projects-edit"
  }, {
    path: "/clients/:clientId/detail",
    component: _61d9368e,
    name: "clients-detail-detail"
  }, {
    path: "/clients/:clientId/edit",
    component: _5e85cd07,
    name: "clients-detail-edit"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/contacts/:contactId",
    component: _136addc4,
    name: "contacts-contactId"
  }, {
    path: "/projects/:projectId",
    component: _1424ee50,
    name: "projects-projectId"
  }, {
    path: "/users/:userId",
    component: _04d4e7e4,
    name: "users-userId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
