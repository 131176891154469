import parsePhoneNumber from 'libphonenumber-js';
import ZionConstants from './ZionConstants';

const util = {
  cache: {},
  async getCached (key, getFunction) {
    if (util[key]) {
      return util.cache[key];
    } else {
      const value = await getFunction();
      util.cache[key] = value;
      return value;
    }
  },
  clearCache (key) {
    if (key) {
      delete util.cache[key];
    } else {
      util.cache = {};
    }
  },
  formatPhoneNumber: (value, countryCode) => {
    if (value && value.trim()) {
      try {
        const parsed = parsePhoneNumber(value, countryCode || 'US');
        return parsed?.formatNational();
      } catch (e) {
        console.warn('attempted to parse invalid phone number', e);
        return '';
      }
    } else {
      return '';
    }
  },
  deepCopy: (object) => {
    return JSON.parse(JSON.stringify(object));
  },
  isDeepEqual: (object1, object2) => {
    const allKeys = { ...Object.keys(object1), ...Object.keys(object2) };
  
    for (const key of Object.values(allKeys)) {
      let value1 = object1[key];
      let value2 = object2[key];
  
      if ((typeof value1 === 'undefined' || value1 === null) && value2 != null && typeof value2 === 'string' && value2.trim().length === 0) {
        return true;
      } else if ((typeof value2 === 'undefined' || value2 === null) && value1 != null && typeof value1 === 'string' && value1.trim().length === 0) {
        return true;
      }

      const isObjects = value1 != null && typeof value1 === 'object' && value2 != null && typeof value2 === 'object';
      const punctuationRegex = /[()\-_$: ]/g;
      if (!isObjects && typeof value1 === 'string' && typeof value2 === 'string') {
        value1 = value1.replaceAll(punctuationRegex, '');
        value2 = value2.replaceAll(punctuationRegex, '');
      }

      if (
        (isObjects && !this.isDeepEqual(value1, value2)) 
        ||
        (!isObjects && value1 !== value2)
      ) {
        return false;
      }
    }
    return true;
  },
  isNumeric: (str) => {
    if (typeof str !== 'string') { 
      return false; 
    } // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseInt(str)); // ...and ensure strings of whitespace fail
  },
  formatBooleanToInt: (val) => {
    if (val) {
      return 1;
    } else {
      return 0;
    }
  },
  formatBooleanToNullInt: (val) => {
    if (val === null || val === undefined) {
      return null;
    }

    if (val) {
      return 1;
    } else {
      return 0;
    }
  },
  formatIntToBoolean: (val) => {
    if (val === null) {
      return false;
    }
    if (val === 1 || val === '1') {
      return true;
    } else {
      return false;
    }
  },
  formatStringToBoolean: (val) => {
    if (val === null) {
      return false;
    }
    if (val.toLowerCase() === 'yes' || val === '1') {
      return true;
    } else {
      return false;
    }
  },
  formatStringToNullableBoolean: (val) => {
    if (val === null || val === undefined || val.toString() === '') {
      return null;
    }
    val = val.toString();
    if (val.toLowerCase() === 'yes' || val === '1') {
      return true;
    } else {
      return false;
    }
  },
  formatNullableBooleanToString: (val) => {
    if (val === null || val === undefined || val.toString() === '') {
      return null;
    }
    if (val) {
      return 'Yes';
    } else {
      return 'No';
    }
  },
  returnOptionsArray (ddOptions, showBlank) {
    const formatted = [];
    if (ddOptions === undefined || ddOptions === null) {
      return '';
    }

    let idx = 0;
    if (showBlank) {
      formatted[0] = { value: '', label: '' };
      idx = 1;
    }
    for (let i = 0; i < ddOptions.length; i++) {
      formatted[idx] = { value: ddOptions[i].ddValue, label: ddOptions[i].ddText, id: ddOptions[i].ddId };
      idx++;
    }
    return formatted;
  },
  returnOptionsArrayGrid: (ddOptions, showBlank) => {
    const formatted = [];
    if (ddOptions === undefined || ddOptions === null) {
      return '';
    }

    let idx = 0;
    if (showBlank) {
      formatted[0] = '';
      idx = 1;
    }
    for (let i = 0; i < ddOptions.length; i++) {
      formatted[idx] = ddOptions[i];
      idx++;
    }
    return formatted;
  },
  returnOptionYearDD (startYear, endYear, showBlank) {
    const yearDD = [];
    let sData = null;
    if (showBlank) {
      sData = {};
      sData.value = '';
      sData.label = '';
      yearDD.push(sData);
    }
    for (let step = startYear; step <= endYear; step++) {
      sData = {};
      sData.value = step;
      sData.label = step;
      yearDD.push(sData);
    };
    return yearDD;
  },
  returnOptionsArrayVar (ddOptions, val, txt, id, showBlank) {
    const formatted = [];
    if (ddOptions === undefined || ddOptions === null) {
      return '';
    }

    let idx = 0;
    if (showBlank) {
      formatted[0] = { value: '', label: '' };
      idx = 1;
    }
    let currOptions = null;
    for (let i = 0; i < ddOptions.length; i++) {
      currOptions = ddOptions[i];
      formatted[idx] = { value: currOptions[val], label: currOptions[txt], id: currOptions[id] };
      idx++;
    }
    return formatted;
  },
  formatDate (date) {
    const isSlashDate = date.indexOf('/') > 0;
    let dateParts = null;
    if (isSlashDate) {
      dateParts = date.split('/');
    } 
    if (isSlashDate && dateParts.length === 3) {
      return [dateParts[2], dateParts[0], dateParts[1].substring(0, 2)].join('-');
    } else {
      if (date.length > 8) {
        if (date.length > 10) {
          return date.substring(0, 10);
        } else {
          return date;          
        }
      }
      return '';
    }
  },
  groupBy (xs, key) {
    return xs.reduce(function (rv, x) {
      if (x[key]) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
      }
      return rv;
    }, {});
  },
  isUndefinedNullOrBlank (val) {
    let ret = false;
    if (val === undefined || val === null || val === false) {
      ret = true;
    }
    if (typeof val === 'string' && val === '') { 
      ret = true; 
    } 

    return ret;
  },
  formatSlashDate (date) {
    if (date && date.trim().length > 0) {
      const isSlashDate = date.indexOf('/') > 0;
      let dateParts = null;
      if (isSlashDate) {
        dateParts = date.split('/');
        if (dateParts[2].length < 4) {
          dateParts[2] = this.convert2digitYear(dateParts[2]);
        }
      } 
      if (isSlashDate && dateParts.length === 3) {
        return [dateParts[2], dateParts[0], dateParts[1].substring(0, 2)].join('-');
      } else {
        if (date.length > 8) {
          return date;
        }
        return '';
      }
    } else {
      return '';
    }
  },
  getFileNameFromUrl (url) {
    if (this.isUndefinedNullOrBlank(url)) {
      return '';
    }

    const urlAry = url.split('/');
    if (urlAry.length > 1) {
      return urlAry[urlAry.length - 1];
    }
    return '';
  },
  escapeHtml (input) {
    if (input) {
      const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
      };
      
      return String(input).replace(/[&<>"'`=/]/g, function (s) {
        return entityMap[s];
      });
    } else {
      return input;
    }
  },
  formatYearFromDate (dte) {
    const newDte = new Date(dte);
    if (newDte instanceof Date) {
      return newDte.getFullYear();
    } else { 
      return '';
    }
  },
  formatMonthFromDate (dte) {
    const newDte = new Date(dte);
    if (newDte instanceof Date) {
      return this.convert2digit(newDte.getMonth());
    } else { 
      return '';
    }
  }, 
  formatMonthStringFromDate (dte) {
    const newDte = new Date(dte);
    if (newDte instanceof Date) {
      return this.formatMonthAbbr(newDte.getMonth());
    } else { 
      return '';
    }
  }, 
  formatDayFromDate (dte) {
    const newDte = new Date(dte);
    if (newDte instanceof Date) {
      return this.convert2digit(newDte.getDay());
    } else { 
      return '';
    }
  },    
  convert2digit (val) {
    if (val !== null && val.toString().length === 1) {
      return '0' + val;
    } else {
      return val;
    }
  },
  formatYearQuarterFromDate (dte) {
    if (dte instanceof Date) {
      return dte.getFullYear().toString() + ' Q' + Math.ceil((dte.getMonth() + 1) / 3);
    } else { 
      return '';
    }
  },    
  formatMonthAbbr (val) {
    if (val > 0 && val < 13) {
      switch (val) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3: 
          return 'Mar';
        case 4: 
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11: 
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return '';                  
      }
    } else {
      return '';
    }
  },
  isExternal (user) {
    return user?.roles?.includes(ZionConstants.ROLE_EXTERNAL);
  }
  
};
export default util;
