// plugins/vue-formulate.js
import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import AutoComplete from '~/components/TroveAutoComplete.vue';

Vue.use(VueFormulate, {
  library: {
    autocomplete: {
      classification: 'text',
      component: AutoComplete
    }
  },
  rules: {
    notNegative (value) {
      return parseFloat(value) >= 0 || 'No negative numbers allowed';
    }
  } 
});
