
export default {
  meta: {
    title: 'ReTECH'
  },
  mounted () {
    this.$store.commit('ui/setWindowHeight', window.innerHeight);  
    window.addEventListener('resize', () => {
      this.$store.commit('ui/setWindowHeight', window.innerHeight);  
    });
  }
};
