export const state = () => ({
  windowHeight: 500
});

export const getters = {
  getWindowHeight (state) {
    return state.windowHeight;
  }
};

export const mutations = {
  setWindowHeight (state, windowHeight) {
    state.windowHeight = windowHeight;
  }
};

export const actions = {
  setWindowHeight (windowHeight) {
    state.windowHeight = windowHeight;
  }
};
