import Vue from 'vue';
import Maska from 'maska';
import Vue2Filters from 'vue2-filters';
import VueLuxon from 'vue-luxon';
import { AgGridVue } from 'ag-grid-vue';
import vSelect from 'vue-select';
import { LicenseManager } from 'ag-grid-enterprise';
import { capitalCase } from 'change-case';
import pluralize from 'pluralize';
import AsyncComputed from 'vue-async-computed';
import ZionUtil from '../util/ZionUtil';
import ZionConstants from '../util/ZionConstants';

LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-046728 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( RE Tech Advisors )_is_granted_a_( Single Application )_Developer_License_for_the_application_( RE Tech App )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( RE Tech App )_need_to_be_licensed___( RE Tech App )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 August 2024 )____[v2]_MTcyNDEwODQwMDAwMA==f50e21e919800cf52a953330ae569838');

Vue.component('AgGridVue', AgGridVue);

Vue.component('VSelect', vSelect);
Vue.use(Vue2Filters);
Vue.use(VueLuxon);
Vue.use(Maska);
Vue.use(AsyncComputed);

export default (context, inject) => {
  inject('util', ZionUtil);
  inject('constants', ZionConstants);
  inject('capitalCase', capitalCase);
  inject('pluralize', pluralize);
};
