// formulate.config.js

import FormulateVSelectPlugin from '@cone2875/vue-formulate-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';

const axiosInstance = axios.create({
  
});

export default {
  plugins: [
    FormulateVSelectPlugin
  ],
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  uploader: axiosInstance,
  uploadUrl: '/api/v2/file'
  // locale: 'de',
  // rules: {
  //   foobar: ({ value }) => ['foo', 'bar'].includes(value)
  // }
};
