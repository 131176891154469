import Vue from 'vue';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import VModal from 'vue-js-modal';

import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

// global registration
Vue.component('Popper', Popper);
Vue.use(VModal, {});
Vue.use(Autocomplete);
Vue.component('VueMultiselect', Multiselect);
