export default function ({ $axios, redirect, error: nuxtError }) {
  // $axios.onError((error) => {
  //   debugger;
  //   if (status === 403) {
  //     redirect('/');
  //   }

  //   return Promise.resolve(error);
  // });
}
