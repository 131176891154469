import Vue from 'vue';

export default function () {
  Vue.filter('capitalize', function (value) {
    if (!value) {
      return '';
    }
    value = value.toString();
    return value.toUpperCase();
  });

  Vue.filter('yesno', function (value, nullVal) {
    if (value === undefined || value === null) {
      return nullVal;
    }

    if (!value || value === 0 || value === 'No') {
      return 'No';
    } else {
      return 'Yes';
    }
  });

  Vue.filter('clearnonnumber', function (value, nullVal) {
    if (value === 'NaN' || value === null || value === '0') {
      return nullVal;
    } else {
      return value;
    }
  });

  Vue.filter('sqftm2', function (value, unitVal) {
    if (value === 'NaN' || value === null || value === '') {
      return '';
    }

    if (unitVal === 'Metric Units') {
      return value + ' m²';
    } 
    return value + ' ft²';
  });

  Vue.filter('dynamicFilter', function (value, name, arg1) {
    let tmpStr = '';
    let tmpNum = 0;

    if (!name) {
      return value;
    }

    if (name === 'commanumber') {   
      tmpStr = Vue.filter('number')(value, arg1);
      tmpStr = Vue.filter('clearnonnumber')(tmpStr, '');
      return tmpStr;
    }

    if (name === 'commaarea') {
      tmpStr = Vue.filter('number')(value, '0,0');
      tmpStr = Vue.filter('clearnonnumber')(tmpStr, '');
      tmpStr = Vue.filter('sqftm2')(tmpStr, arg1);
      return tmpStr;
    }

    if (name === 'zionpercent') {
      tmpNum = value / 100;
      return Vue.filter('percent')(tmpNum);
    }

    if (name === 'handlenull') {
      if (value === undefined || value === null) {
        return arg1;
      }

      return value;
    }

    return Vue.filter(name)(value, arg1);  
  });

  Vue.filter('viewdata', function (value) {
    return value;
  });
}
