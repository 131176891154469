
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    vmodel: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      selectedIndex: 0,
      elementId: this._uid
    };
  },
  computed: {
    selection () {
      if (this.filteredOptions[this.selectedIndex]) {
        return this.filteredOptions[this.selectedIndex];
      }
      return false;
    },
    filteredOptions () {
      if (Array.isArray(this.options) && this.vmodel) {
        const isAlreadySelected = this.options.find(option => option.label === this.vmodel);

        if (!isAlreadySelected) {
          return this.options
            .filter(option => option.label.toLowerCase().includes(this.vmodel.toLowerCase()));
        }
      }
      return [];
    }
  },  
  watch: {
    vmodel () {
      this.selectedIndex = 0;
    }
  },

  methods: {
    increment () {
      const length = this.filteredOptions.length;
      if (this.selectedIndex + 1 < length) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
    },
    decrement () {
      const length = this.filteredOptions.length;
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      } else {
        this.selectedIndex = length - 1;
      }
    },
    update () {
      if (this.filteredOptions?.length > 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.vmodel = this.filteredOptions[0].label;
      }
    }
  }
};
